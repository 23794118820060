/**
* Generated automatically at built-time (2025-01-31T10:39:28.246Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outil-3D-guide-cuisine-d-exterieur",templateKey: "sites/104-62c3a701-7c4a-40d9-9251-8ddce596e5b5"};